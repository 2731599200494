import {
    ARPU3D,
    ASO_INSTALLS,
    COUNTRY,
    CVR_IMPRESSIONS_INSTALLS,
    CVR_IMPRESSIONS_PPV,
    CVR_PPV_INSTALLS,
    DATE,
    DAU3D,
    IMPRESSIONS,
    INST3D,
    INSTALLS,
    PAGE_VIEWS,
    PAYERS3,
    PLATFORM,
    RET3D,
    NET_REVENUE_TOTAL_3D,
    SOURCE,
    TITLE
} from "../common/ColumnDefs";
import {show} from "../common/Utils";
import {FilterDef} from "./common/ReportFiltersDef";

export const ASO_REPORT_COLUMNS = [
    TITLE, PLATFORM, SOURCE, COUNTRY, DATE, show(IMPRESSIONS), PAGE_VIEWS, INSTALLS,
    CVR_IMPRESSIONS_PPV, CVR_IMPRESSIONS_INSTALLS, CVR_PPV_INSTALLS,
    show(NET_REVENUE_TOTAL_3D), show(PAYERS3), DAU3D, INST3D, RET3D, ARPU3D
]

export const ASO_REPORT_DEFAULT_GROUPINGS = [TITLE.colId, PLATFORM.colId, SOURCE.colId, COUNTRY.colId, DATE.colId];

export const ASO_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: DATE.colId, sort: "asc"},
    {colId: ASO_INSTALLS.colId, sort: "desc"}
]

export const ASO_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.PLATFORM,
    FilterDef.COUNTRY,
    FilterDef.ASO_SOURCE,
    FilterDef.INSTALL_DATE
]

import {
    AD_FCST360,
    C2W,
    C_RET1D,
    CLICKS,
    COUNTRY,
    CPA3D,
    CPA7D,
    CPC,
    CPI,
    CPM,
    CPW,
    CREATIVE_CONCEPT,
    CREATIVE_FULL_NAME,
    CREATIVE_NAME_ALL_ENDCARD,
    CREATIVE_NAME_NO_ENDCARD,
    CREATIVE_NAME_PLAYABLE_ENDCARD,
    CS_CREATIVE_REPORT,
    CTR,
    CVR,
    DATE,
    DAU1D,
    DAU3D,
    DAU7D,
    FCST360,
    FULL_APP_NAME,
    IMPRESSIONS,
    INST1D_CREATIVE_REPORT,
    INST3D_CREATIVE_REPORT,
    INST7D_CREATIVE_REPORT,
    INSTALLS,
    IPM,
    IR,
    LOCALIZATION,
    LTV360,
    MEDIA_SOURCE,
    PAYERS3,
    PAYERS3_PERCENT,
    PAYERS7,
    PAYERS7_PERCENT,
    PAYMENTS3,
    PAYMENTS7,
    PLATFORM,
    RET1D_CREATIVE_REPORT,
    RET3D_CREATIVE_REPORT,
    RET7D_CREATIVE_REPORT,
    REVENUE0D,
    NET_REVENUE_TOTAL_1D,
    NET_REVENUE_TOTAL_3D,
    NET_REVENUE_TOTAL_7D,
    ROAS,
    ROAS0D_FACT,
    ROAS1D_FACT,
    ROAS3D_FACT,
    ROAS7D_FACT,
    RPM1D,
    SPEND,
    WEEK,
    WHALES
} from "../common/ColumnDefs";
import {setHighlighter, show} from "../common/Utils";
import {creativeReportHighlighter} from "../common/ValueFormatters";
import {FilterDef} from "./common/ReportFiltersDef";

export const CREATIVE_REPORT_COLUMNS = [
    WEEK, FULL_APP_NAME, PLATFORM, COUNTRY, DATE, MEDIA_SOURCE, CREATIVE_CONCEPT, CREATIVE_FULL_NAME,
    CREATIVE_NAME_ALL_ENDCARD, CREATIVE_NAME_PLAYABLE_ENDCARD, CREATIVE_NAME_NO_ENDCARD, LOCALIZATION,
    setHighlighter(LTV360, creativeReportHighlighter), SPEND, show(IMPRESSIONS), show(CLICKS), INSTALLS,
    CPM, CPC, CPI, CTR, IR, CVR, IPM, ROAS,
    ROAS0D_FACT, ROAS1D_FACT, ROAS3D_FACT, ROAS7D_FACT, setHighlighter(CPA3D, creativeReportHighlighter),
    setHighlighter(show(CPA7D), creativeReportHighlighter),
    setHighlighter(RET1D_CREATIVE_REPORT, creativeReportHighlighter),
    setHighlighter(RET3D_CREATIVE_REPORT, creativeReportHighlighter),
    setHighlighter(RET7D_CREATIVE_REPORT, creativeReportHighlighter),
    setHighlighter(show(PAYERS3_PERCENT), creativeReportHighlighter),
    setHighlighter(show(PAYERS7_PERCENT), creativeReportHighlighter), DAU1D, DAU3D, DAU7D, C_RET1D, RPM1D,
    INST1D_CREATIVE_REPORT, INST3D_CREATIVE_REPORT, INST7D_CREATIVE_REPORT, PAYMENTS3, PAYMENTS7, PAYERS3, PAYERS7,
    FCST360, REVENUE0D, NET_REVENUE_TOTAL_1D, NET_REVENUE_TOTAL_3D, NET_REVENUE_TOTAL_7D, AD_FCST360, WHALES, C2W, CPW, CS_CREATIVE_REPORT
]

export const CREATIVE_REPORT_DEFAULT_GROUPINGS = [
    FULL_APP_NAME.colId, PLATFORM.colId, MEDIA_SOURCE.colId, COUNTRY.colId, CREATIVE_NAME_PLAYABLE_ENDCARD.colId, LOCALIZATION.colId
];

export const CREATIVE_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: WEEK.colId, sort: "asc"},
    {colId: DATE.colId, sort: "asc"},
    {colId: SPEND.colId, sort: "desc"}
]

export const CREATIVE_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.PLATFORM,
    FilterDef.CREATIVE_REPORT_MEDIA_SOURCE,
    FilterDef.CREATIVE_REPORT_COUNTRY,
    FilterDef.CREATIVE_TYPE,
    FilterDef.INSTALL_DATE,
]
